@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
}

:root {
  overflow-x: hidden;
}

body {
  margin: 0;
  background-color: #00214f;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
