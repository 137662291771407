/* color change on hover navbar items */
.navbar-item {
  &:hover {
    color: #C1292E;
  }
}

.follow-me-item {
  &:hover {
    color: #00214f;
  }
}

/* underline animation for menu items */
.menu-item {
  position: relative;
  cursor: pointer;
  transition: transform 1s ease-in-out;
  &::after {
      content: '';
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 2px;
      bottom: 0;
      left: 0;
      transform-origin: bottom right;
      background-color: white;
      transition: transform 0.35s ease-in-out;
    }
  &:hover::after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
}

/* pulse live sign animation */
.livenow > div {
  vertical-align: middle;
  width: 22px;
  height: 22px;
  border-radius: 100%;
  position: absolute;
  margin: 0 auto;
  border:3px solid rgba(255,0,0,1);
  -webkit-animation: live 1.4s infinite ease-in-out;
  animation: live 1.4s infinite ease-in-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  &:nth-child(1) {
    background-color:rgba(255,0,0,0.3);
    background-color:rgba(255,0,0,1);
    -webkit-animation-delay: -0.1s;
    animation-delay: -0.1s;
  }
  &:nth-child(2) {
    -webkit-animation-delay: 0.16s;
    animation-delay: 0.16s;
  }
  &:nth-child(3) {
    -webkit-animation-delay: 0.42s;
    animation-delay: 0.42s;
    border:3px solid rgba(255,255,255,0.5);
  }
  &:nth-child(4) {
    border:3px solid rgba(255,255,255,1);
    -webkit-animation-delay: -0.42s;
    animation-delay: -0.42s;
  }
}

@-webkit-keyframes live {
  0%, 80%, 100% { -webkit-transform: scale(0.6) }
  40% { -webkit-transform: scale(1.0) }
}
@keyframes live {
  0%, 80%, 100% { 
    transform: scale(0.6);
    -webkit-transform: scale(0.6);
  } 40% { 
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}

/* Initial state: hidden to the left */
.card {
  transform: translateX(-100%); /* Slide element out of viewport */
  transition: transform;
}

/* Animation: slide in from the left */
.slide-in {
  transform: translateX(0); /* Slide element in from the left */
  transition: transform 0.8s ease; /* Apply transition to the transform property */
}

/* Loading spinner */

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


& .navbar, .dropdown {
  display: block;
  position: relative;
  height: 48px;
}
& .dropdown-trigger {
  cursor: pointer;
  height: 100%;
  transition: transform 0.2s ease-in-out 0.2s;
  & * {
      cursor: pointer;
  }
  & span {
      display: block;
      width: 45px;
      height: 4px;
      margin-bottom: 10px;
      margin-left: 5px;
      margin-right: 5px;
      position: relative;
      background: white;
      border-radius: 3px;
      z-index: 1;
      &:last-of-type {
          margin-bottom: 0;
      }
  }
  & .line-1 {
      transition: transform 0.2s ease-in-out;
  }
  & .line-2 {
      transition: transform 0.2s ease-in-out;
  }
  & .line-3 {
      transition: transform 0.2s ease-in-out;
  }
  &.active {
      transform: rotate(45deg);
      & .line-1 {
          transform: translateY(14px);
      }
      & .line-2 {
          transform: rotate(90deg);
      }
      & .line-3 {
          transform: translateY(-14px);
      }
  }
}

/* Styles for .dropdown-menu */
& .dropdown-menu {
  position: absolute;
  top: 65px;
  right: -290px;
  margin: 0;
  padding: 1rem;
  text-align: center;
  font-size: 1.5rem;
  background-color: #313436;
  box-shadow: 8px 8px 16px 8px rgba(0,0,0,0.2);
  border-radius: 5px;
  z-index: 1;
  transition: transform 0.3s ease-in-out;
}

/* Styles for .dropdown-menu when it has the class .show */
& .dropdown-menu.show {
  transform: translateX(-255px);
}

/* Styles for .menu-item */
& .dropdown-menu .menu-item {
  margin: 1.5rem;
  position: relative;
  cursor: pointer;
  transition: transform 1s ease-in-out;
}

.app-loader-container {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  width: 100vw;
  height: 100vh;
}

/* styles for lazy images */
.blurred-img {
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(10px);
}

.blurred-img img {
  opacity: 0;
}

.blurred-img::before {
  content: "";
  position: absolute;
  inset: 0;
  opacity: 0;
  border-radius: 50%;
  animation: pulse 2.5s infinite;
  background-color: white;
}

@keyframes pulse {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 0;
  }
}

.blurred-img.loaded::before {
  animation: none;
  content: none;
}

.blurred-img img {
  opacity: 0;
  transition: opacity 250ms ease-in-out;
}

.blurred-img.loaded {
  filter: none;
}

.blurred-img.loaded img {
  opacity: 1;
}